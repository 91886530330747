import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { TagInput, SubTitle, Spacer, Button } from "@dspworkplace/ui";
import SettingsContext from "../context";
import { getCompanyId, showToast } from "../../../Utilities";
import { useAuth } from "../../../Auth";
import { LoadingView } from "../../../components/Loading";
import { debugLog } from "../../../AppTwilioConversation/utils";
import { AccessCheck, IsGranted } from "../../../security";

const TagInputContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.templateColumns ? props.templateColumns : "auto auto")};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : "8px")};
    width: ${props => (props.width ? props.width : "612px")};
    align-items: center;

    label {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div.tagEventPrevent {
        width: ${props => (props.width ? props.width : "612px")};
    }

    ul {
        max-width: ${props => (props.width ? props.width : "612px")};
    }

    ul.big {
        max-width: unset;
        width: auto;
    }

    input {
        width: 100%;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;
// @ts-ignore
const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    color: #707070;
    font-weight: 500;

`;

function getBotManagersState(state) {
    try {
        // console.log({getBotManagersState: state});
        // console.log({getBotManagersState: state.company});
        if (state.company && state.company.botManagers) {
            let openShifts: string[] = Array.isArray(state.company.botManagers.openShifts) ? state.company.botManagers.openShifts : [];
            // @ts-ignore
            let swapShifts: string[] = Array.isArray(state.company.botManagers.swapShifts) ? state.company.botManagers.swapShifts : [];
            // @ts-ignore
            let dropShifts: string[] = Array.isArray(state.company.botManagers.dropShifts) ? state.company.botManagers.dropShifts : [];
            // @ts-ignore
            let antiSwears: string[] = Array.isArray(state.company.botManagers.antiSwears) ? state.company.botManagers.antiSwears : [];
            // @ts-ignore
            let mileageAlerts: string[] = Array.isArray(state.company.botManagers.mileageAlerts) ? state.company.botManagers.mileageAlerts : [];
            return { openShifts, swapShifts, dropShifts, antiSwears, mileageAlerts };
        }
        return { openShifts: [], swapShifts: [], dropShifts: [], antiSwears: [], mileageAlerts: [] };
    } catch (e) {
        console.error({ getBotManagersState: e });
        return { openShifts: [], swapShifts: [], dropShifts: [], antiSwears: [], mileageAlerts: [] };
    }
}

function formatForTagInput(list, user) {
    try {
        if (list.includes(user.userId)) {
            return { name: user.friendlyName, value: user.userId, selected: true };
        } else {
            return { name: user.friendlyName, value: user.userId }
        }
    } catch (e) {
        console.error({ formatForTagInput: e, list, user });
        return { name: user.friendlyName, value: user.userId }
    }
}

async function fetchBotManagers(api, state, setOpenShiftManagers, setSwapShiftManagers, setDropShiftManagers, setAntiSwearManagers, setMileageAlertsManagers) {
    try {
        debugLog('fetchBotManagers');
        let { data } = await api.post('/api/bot/twilio/employees', {
            companyId: getCompanyId(),
            notEqualRole: 'ROLE_DELIVERY_ASSOCIATE'
        });
        let users: any[] = data.users;
        debugLog(users);
        let botManagers = getBotManagersState(state);
        debugLog({ botManagers });
        // @ts-ignore
        let openShifts: string[] = botManagers.openShifts;
        // @ts-ignore
        let swapShifts: string[] = botManagers.swapShifts;
        // @ts-ignore
        let dropShifts: string[] = botManagers.dropShifts;
        // @ts-ignore
        let antiSwears: string[] = botManagers.antiSwears;
        // @ts-ignore
        let mileageAlerts: string[] = botManagers.mileageAlerts;
        let s1: any[] = [];
        let s2: any[] = [];
        let s3: any[] = [];
        let s4: any[] = [];
        let s5: any[] = [];
        for (let i = 0; i < users.length; i++) {
            let user = users[i];
            s1.push(formatForTagInput(openShifts, user));
            s2.push(formatForTagInput(swapShifts, user));
            s3.push(formatForTagInput(dropShifts, user));
            s4.push(formatForTagInput(antiSwears, user));
            s5.push(formatForTagInput(mileageAlerts, user));
        }
        setOpenShiftManagers(s1);
        setSwapShiftManagers(s2);
        setDropShiftManagers(s3);
        setAntiSwearManagers(s4);
        setMileageAlertsManagers(s5);
        return true
    } catch (e) {
        console.error({ fetchBotManagers: e });
        return false;
    }
}

async function updateBotManagers(api, botManagers) {
    try {
        await api.post('/api/bot/twilio/employees', {
            companyId: getCompanyId(),
            update: 'botManagers',
            botManagers: botManagers
        });
        return true
    } catch (e) {
        return false;
    }
}

const BotManagers = () => {
    // @ts-ignore
    const { api } = useAuth();
    // @ts-ignore
    const [state, dispatch] = useContext(SettingsContext);
    const { register, handleSubmit } = useForm();
    // @ts-ignore
    const [openShiftManagers, setOpenShiftManagers] = useState([]);
    const [swapShiftManagers, setSwapShiftManagers] = useState([]);
    const [dropShiftManagers, setDropShiftManagers] = useState([]);
    const [antiSwearManagers, setAntiSwearManagers] = useState([]);
    // @ts-ignore
    const [mileageAlertsManagers, setMileageAlertsManagers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);

    const onSubmit = async (data, evt) => {
        let form = evt.target;
        try {
            // console.log({data});
            setLoadingSave(true);
            form.querySelector('button').disabled = true;
            let success = await updateBotManagers(api, data);
            if (success) {
                let updatedCompany = state.company;
                updatedCompany['botManagers'] = data;
                updatedCompany['showMobileReleaseShift'] = updatedCompany.showMobileReleaseShift;
                updatedCompany['showMobileSwapShift'] = updatedCompany.showMobileSwapShift;
                // console.log({updatedCompany});
                dispatch({ type: 'UPDATE_COMPANY', payload: { company: updatedCompany } });
                await showToast({
                    type: 'success',
                    title: 'Bot Managers',
                    content: 'Updates completed!'
                });
            }
            form.querySelector('button').disabled = false;
            setLoadingSave(false);
        } catch (e) {
            setLoadingSave(false);
            await showToast({ type: 'error', title: 'Bot Managers', content: JSON.stringify(e) });
            form.querySelector('button').disabled = false;
        }
    };

    useEffect(() => {
        setLoading(true);
        // console.log('BotManagers');
        // console.log(state.company);
        fetchBotManagers(api, state, setOpenShiftManagers, setSwapShiftManagers, setDropShiftManagers, setAntiSwearManagers, setMileageAlertsManagers).then(() => {
            // console.log('Done');
            setLoading(false);
        });
    }, [state.company]);

    if (loading) {
        return <LoadingView containerWidth={'400px'} imgWidth={'40px'} />
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SubTitle>Workbot Alert Managers</SubTitle>
            <Spacer bottom={2} />
            <RowContainer>
                <TagInputContainer>
                    <TagInput
                        label="Approvers for Open Shift Pick-up Request"
                        options={openShiftManagers}
                        size="big"
                        name={'openShifts'}
                        ref={register}
                        // @ts-ignore
                        placeholder={openShiftManagers.filter(e => e.selected).length === 0 ? "Default to Station Manager and/or Assistant Station Manager if they exist." : ""}
                    />
                </TagInputContainer>
            </RowContainer>
            <Spacer bottom={4} />
            <RowContainer>
                <TagInputContainer>
                    <TagInput
                        label="Approvers for driver Swap Shift Request"
                        options={swapShiftManagers}
                        size="big"
                        name={'swapShifts'}
                        ref={register}
                        // @ts-ignore
                        placeholder={swapShiftManagers.filter(e => e.selected).length === 0 ? "Default to Station Manager and/or Assistant Station Manager if they exist." : ""}
                    />
                </TagInputContainer>
            </RowContainer>
            <Spacer bottom={4} />
            <RowContainer>
                <TagInputContainer>
                    <TagInput
                        label={"Approvers for driver Drop Shift request (also Payroll ID/Employee Name match alert)"}
                        options={dropShiftManagers}
                        size="big"
                        name={'dropShifts'}
                        ref={register}
                        // @ts-ignore
                        placeholder={dropShiftManagers.filter(e => e.selected).length === 0 ? "Default to Station Manager and/or Assistant Station Manager if they exist." : ""}
                    />
                </TagInputContainer>
            </RowContainer>
            <Spacer bottom={5} />
            <RowContainer>
                <TagInputContainer>
                    <TagInput
                        label={"Swearbot Task Reviewers"}
                        // label="Chat Administrators/Swearbot Task Reviewers"
                        options={antiSwearManagers}
                        size="big"
                        name={'antiSwears'}
                        ref={register}
                        // @ts-ignore
                        placeholder={antiSwearManagers.filter(e => e.selected).length === 0 ? "Default to Employee's manager" : ""}
                    />
                </TagInputContainer>
            </RowContainer>
            <Spacer bottom={5} />
            <RowContainer>
                <TagInputContainer>
                    <TagInput
                        label={"Mileage Task Reviewers"}
                        options={mileageAlertsManagers}
                        size="big"
                        name={'mileageAlerts'}
                        ref={register}
                        // @ts-ignore
                        placeholder={mileageAlertsManagers.filter(e => e.selected).length === 0 ? "Default to Owner" : ""}
                    />
                </TagInputContainer>
            </RowContainer>
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type='primary'>{loadingSave ? 'Saving ...' : 'Save'}</Button>
            </IsGranted>
        </form>
    );
};

export default BotManagers;
