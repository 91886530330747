import React from 'react';
import {useForm} from "react-hook-form";
import {Form, TextArea, ManageDisabledCheckBox} from "./Item";
import {PassQuestionType} from "./store";
import {Button, Dropdown} from '@dspworkplace/ui';
import { useRecoilValue } from 'recoil';
import {planDetailsState} from "../../../state/planDetail";
import AccessCheck from "../../../security/AccessCheck";
import { getCompanyId } from '../../../Utilities';

type Props = {
    sectionId: number;
    itemId: number;
    item: {
        id: number;
        name: string;
        type: number;
        content: PassQuestionType;
    };
    type: number;
    updateItem: (data: any) => void;
}

function PassQuestionForm(props: Props) {
    const {item, updateItem} = props;
    
    const {
        name,
        description,
        isPassRequired,
        failLabel,
        passLabel,
        extraLabel,
        enableExtraLabel,
        isCommentRequired,
        isPhotoRequired,
        notifyIfChosen,
        notifyIfNotChosen,
        notifyIfExtraChosen,
        messageIfExtraChosen,
        messageIfNotChosen,
        requireReasonIfExtraChosen,
        requireReasonIfFailChosen,
        additionalFailQuestion=['log'],
        workbotAlertManager = 'MILEAGE_ALERT',
        disabledQuestion,
    } = item.content;
    const {register, handleSubmit, watch, reset} = useForm({
        defaultValues: {
            name,
            description,
            passLabel,
            failLabel,
            isPassRequired,
            extraLabel,
            enableExtraLabel,
            isCommentRequired,
            isPhotoRequired,
            notifyIfChosen,
            notifyIfNotChosen,
            notifyIfExtraChosen,
            messageIfExtraChosen,
            messageIfNotChosen,
            requireReasonIfExtraChosen,
            requireReasonIfFailChosen,
            additionalFailQuestion,
            workbotAlertManager,
            disabledQuestion,
        },
    });

    const enableExtraLabelWatch = watch("enableExtraLabel");
    const avoidPermission = [85];
    const companyId = getCompanyId();
    const {tier} = useRecoilValue(planDetailsState);
    const ISGRANTED_VEHICLE_MAINTENANCE_PLAN = avoidPermission.includes(companyId) === true ? true : AccessCheck.isGranted({ plan: tier, feature:AccessCheck.addons.VEHICLE_MAINTENANCE_PLAN, role:null, ops:null, station:null });
    const workbotAlertManagers = [{name: "Approvers for Open Shift Pick-up Request",value: 'OPEN_SHIFT'},{name: "Approvers for driver Swap Shift Request",value: 'SWAP_SHIFT'},{name: "Approvers for driver Drop Shift request (also Payroll ID/Employee Name match alert)",value: 'RELEASE_SHIFT'},{name: "Swearbot Task Reviewers",value: 'SWEARBOT_SHIFT'},{name: "Mileage Task Reviewers",value: 'MILEAGE_ALERT'}];
    
    return (
        <Form onSubmit={handleSubmit(updateItem)}>
            <label htmlFor="name">Name</label>
            <input name="name" ref={register({
                required: true
            })}/>
            <label htmlFor="description">Description</label>
            <TextArea name="description" ref={register}/>
            <label htmlFor="passLabel">Pass Label</label>
            <input name="passLabel" ref={register({
                required: true
            })}/>
            <label htmlFor="failLabel">Fail Label</label>
            <input name="failLabel" ref={register({
                required: true
            })}/>
            <label>If Failed Label, need to add the following options:</label>
            <div>
                <div style={{display:"flex", gap:"10px"}}><input name='additionalFailQuestion' type="checkbox" className={'checkbox'} ref={register} value={'log'} disabled={true} /><label htmlFor="additionalFailQuestion[0]">Log Failed response in inspection</label></div>
                <div style={{display:"flex", gap:"10px"}}>
                <input name="additionalFailQuestion" type="checkbox" className={'checkbox'} ref={register} value={'workbot-notification'}/><label htmlFor="additionalFailQuestion[1]">Send workbot notification to &nbsp;
                    <Dropdown
                        name='workbotAlertManager'
                        placeholder='Select Workbot Alert Manager'
                        ref={register()}
                        options={workbotAlertManagers}
                        defaultValue={workbotAlertManager}
                    />
                    </label>
                </div>
                <div style={{display:"flex", gap:"10px"}}><input name="additionalFailQuestion" type="checkbox" className={'checkbox'} ref={register} disabled={!ISGRANTED_VEHICLE_MAINTENANCE_PLAN} value={'task-tracker'}/><label htmlFor="additionalFailQuestion[2]"> Open ticket in TaskTracker with a PENDING status</label></div>
            </div>
           

            <label>Is Extra Label required?</label>
            <input name="enableExtraLabel" type="checkbox" className={'checkbox'} ref={register}/>
            {enableExtraLabelWatch &&
            <>
                <label htmlFor="extraLabel">Extra Label</label>
                <input name="extraLabel" ref={register}/>
                {/*<label>Notify if Extra label chosen?</label>*/}
                {/*<input name="notifyIfExtraChosen" type="checkbox" className={'checkbox'} ref={register}/>*/}
                <label htmlFor="messageIfExtraChosen">Message if Extra is chosen</label>
                <input name="messageIfExtraChosen" ref={register}/>
                <label>Require reason If Extra is chosen</label>
                <input name="requireReasonIfExtraChosen" type="checkbox" className={'checkbox'} ref={register}/>
            </>}
            <label>Is required to Pass?</label>
            <input name="isPassRequired" type="checkbox" className={'checkbox'} ref={register}/>
            {/*<label>Notify if Pass chosen?</label>*/}
            {/*<input name="notifyIfChosen" type="checkbox" className={'checkbox'} ref={register}/>*/}
            {/*<label>Notify if Not Pass chosen?</label>*/}
            {/*<input name="notifyIfNotChosen" type="checkbox" className={'checkbox'} ref={register}/>*/}
            <label htmlFor="messageIfNotChosen">Message if Not Pass is chosen</label>
            <input name="messageIfNotChosen" ref={register}/>
            <label>Require reason If Fail is chosen</label>
            <input name="requireReasonIfFailChosen" type="checkbox" className={'checkbox'} ref={register}/>
            <label>Require picture</label>
            <input name="isPhotoRequired" type="checkbox" className={'checkbox'} ref={register}/>
            <Button typeStyle="delete" style={{width: '180px'}} onClick={
                () => {
                    reset();
                }
            }>
                Reset
            </Button>
            <ManageDisabledCheckBox className="d_flex">
                <Button typeStyle="primary" Type="submit" style={{ width: "180px" }}>
                    Save
                </Button>
                <ManageDisabledCheckBox className="d_flex ml_100">
                    <input name="disabledQuestion" type="checkbox" className={"checkbox"} ref={register} />
                    <label>Disable Question</label>
                </ManageDisabledCheckBox>
            </ManageDisabledCheckBox>
        </Form>
    );
}

export default PassQuestionForm;