import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useAuth, useLocalStorage, getIsEnableSMS, setIsEnableSMS, getCompanyId, setAllowsDispatcherPermission } from "../../../Auth";
import Loading, { useLoading } from "../../../components/Loading";
import { useForm } from "react-hook-form";
import moment from "moment";
import Modal, { useModal } from "../../../components/Modal";
import { Table, TableData, TableRow, TableHeader } from "../../../components/Table";
import People from "../../../components/People";
import { Button, CheckBox, Spacer, Text, Theme, ThirdTitle, Alert } from "@dspworkplace/ui";
import { dialogPromise } from "../../../components/Dialog";
import styled from "styled-components";
import Store from "../Day/store";
import LoadOutStore from "../../SchedulerLoadOut/context";
import WeekStore from "../Week/context/weekStore";
import { Badge, Tag } from "../../../components/UI";
import StationSelector, { useSelectedStation } from "../../../components/StationSelector";
import Empty from "../../../components/Empty";
import toast from "../../../components/Toasts/toast";

const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Container = styled.div`
    position: relative;
    display: inline-block;
`;

const DropdownWrapper = styled.ul`
    position: absolute;
    top: 100%;
    padding: 0px;
    margin: 0;
    top: 38px;
    background: white;
    color: rgb(247, 147, 30);
    border: 2px solid rgb(247, 147, 30);
    box-shadow: 0px 3px 6px rgb(204, 204, 204);
    border-radius: 2px;
    list-style: none;
    z-index: 10;
`;

const DropdownItem = styled.li`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    height: 32px;
    cursor: pointer;
    padding: 0px 4px 0px 8px;

    &:hover {
      background: rgb(247, 147, 30);
      color: white;
    }

    &::before {
      content: " ";
      height: 100%;
      width: 6px;
      background: transparent;
      display: block;
      position: absolute;
      right: -7px;
    }
`;

const ItemLabel = styled.span`
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    flex: 1 1 0%;
    font-size: 14px;
    line-height: 16px;
    margin-right: 4px;
`;

const PublishButton = styled.span`
    width: 120px;
    white-space: nowrap;
    position: relative;
    font-family: circe-rounded;
    font-weight: bold;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    background: rgb(247, 147, 30);
    color: rgb(255, 255, 255);
    padding: 0px 10px;
`
const LoadingWrapper = styled.div`
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.7);
    z-index:10;
    >img {
        width:90px;
        height:90px;
        position:absolute;
        left: 50%;
        margin-left: -45px;
        top: 40%;
    }
`;

const PublishPopUp = ({ date, week, year, view, fakeRefresh }) => {
    const [visible, setVisible] = useModal(false);
    let StoreContext = view == "day" ? Store : view == "loadOut" ? LoadOutStore : WeekStore;
    const [state, dispatch] = useContext(StoreContext);

    let selectedDate = date != "" ? moment(date).format("YYYY-MM-DD") : null;

    const [selectedStation] = useSelectedStation();
    const [data, setData] = useState([]);
    const [menuVisible, setMenuVisible] = useState(false);
    const [isLoading, setLoading] = useLoading(true);

    const [updateScheduler, setUpdatedScheduler] = fakeRefresh;

    const { api } = useAuth();

    const options = [
        { value: 'publishAll', label: 'Publish all - do not notify' },
        { value: 'publishAllNotify', label: 'Publish all - notify all' },
        { value: 'openDialog', label: 'Open dialogue' },
    ];

    const buildParams = (week, selectedDate, selectedStation, year, view, functionName) => ({
        actions: {
            response: {
                TempDriverRoute: {
                    custom: {
                        functionName: functionName,
                        get: functionName,
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "status",
                            "routeId",
                            "createdBy",
                            "kickoffLog",
                            "vehicleDriverRecord",
                            "oldDriver",
                            "oldDriverRoute",
                            "events",
                            "updatedBy",
                        ],
                        criteria: {
                            wknumber: week,
                            unpublishDataDate: selectedDate,
                            station: selectedStation,
                            year: year,
                            type: view,
                        },
                    },
                },
                Company: {
                    custom: {
                        functionName: "getSmsPermission",
                        get: "getSmsPermission",
                        criteria: {
                            company: getCompanyId(),
                        },
                        excludes: {},
                        includes: {},
                    },
                },
                CompanySetting: {
                    custom: {
                        functionName: "findOneBy",
                        get: "companySetting",
                        criteria: {
                            company: getCompanyId(),
                        },
                        excludes: {},
                        includes: [
                            "allowsDispatcherPermission"
                        ],
                    },
                },
            },
        },
    });

    const fetchAll = useCallback(async () => {
        setLoading(true)
        const params = buildParams(week, selectedDate, selectedStation, year, view, "getUnPublishTempDriverRouteList");
        try {
            let response = await api.post("/api/lazy/manage/data", params, {
                cache: {
                    maxAge: 0,
                },
            });
            await setIsEnableSMS(response.data.data.getSmsPermission);
            await setAllowsDispatcherPermission(response.data.data?.companySetting?.allowsDispatcherPermission);
            setData(response.data.data.getUnPublishTempDriverRouteList);
            setLoading(false);
        } catch (error) {
            console.error({ error });
            console.error("[ERROR]", error.message ? error.message : "");
            alert(error.message);
        }
    }, [api, week, selectedDate, selectedStation, year, view]);

    const handleMouseEnter = (event) => {
        event.preventDefault();
        if (visible) return;
        setMenuVisible(true);
    };

    const handleMouseLeave = (event) => {
        setMenuVisible(false);
    };

    const handleMenuClick = (action) => {
        if (action === 'publishAllNotify') {
            publishAll(true);
        } else if (action === 'openDialog') {
            openDialog();
        } else if (action === 'publishAll') {
            publishAll();
        }
        setMenuVisible(false);
    };

    const publishAll = async (notifyDrivers) => {
        setLoading(true);
        try {
            await toast({
                type: "info",
                title: "Publish changes…",
                content: "You can wait for your work while we publish your selected changes.",
            });
            dispatch({ type: "LOADING", payload: { status: true } });
            const params = buildParams(week, selectedDate, selectedStation, year, view, "getUnPublishTempDriverRouteList");

            let response = await api.post("/api/lazy/manage/data", params, {
                cache: {
                    maxAge: 0,
                },
            });

            const currentData = response.data.data.getUnPublishTempDriverRouteList;
            const ids = currentData.map(item => item.id);
            const smsMessage = currentData.reduce((acc, item) => {
                acc[item.id] = item.notificationText;
                return acc;
            }, {});

            const publishParams = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "publishDriverRoute",
                                get: "publishDriverRoute",
                                excludes: ["skill", "vehicle", "device", "driver", "station", "shiftType", "schedule"],
                                criteria: {
                                    tempDriverRouteIds: ids,
                                    view: view,
                                    smsMessage: smsMessage,
                                    notifyDriver: notifyDrivers ? ['Chat'] : []
                                },
                            },
                        },
                    },
                },
            };

            const publishResponse = await api.post("/api/lazy/manage/data", publishParams);

            dispatch({ type: "CLEAN_CHANGES", payload: { qty: currentData.length } });
            setUpdatedScheduler(updateScheduler + 1);
            //skip other station driver route display in model
            let res = publishResponse.data.data.publishDriverRoute;
            if (res?.success === true && res?.otherStationDriverRoutes.length > 0) {
                await openOtherStationDriverRouteDisplayModal(res?.otherStationDriverRoutes, api, dispatch, updateScheduler, setUpdatedScheduler, view);
            }
        } catch (error) {
            console.error({ error });
            console.error("[ERROR]", error.message ? error.message : "");
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };

    const openDialog = () => {
        setData([]);
        setMenuVisible(false);
        setVisible(true);
    };

    const dropAllChanges = async () => {
        setLoading(true);
        try {
            const params = buildParams(week, selectedDate, selectedStation, year, view, "getUnPublishTempDriverRouteList");

            let response = await api.post("/api/lazy/manage/data", params, {
                cache: {
                    maxAge: 0,
                },
            });

            const currentData = response.data.data.getUnPublishTempDriverRouteList;
            const dropRouteIds = currentData.map(item => item.id);

            const dropParams = {
                actions: {
                    response: {
                        TempDriverRoute: {
                            custom: {
                                functionName: "removeDropUnpublishChanges",
                                get: "checkSequenceDriverRoutes",
                                excludes: [
                                    "skill",
                                    "vehicle",
                                    "device",
                                    "driver",
                                    "station",
                                    "shiftType",
                                    "schedule",
                                    "status",
                                    "routeId",
                                    "createdBy",
                                    "kickoffLog",
                                    "vehicleDriverRecord",
                                    "oldDriver",
                                    "oldDriverRoute"
                                ],
                                criteria: {
                                    tempDriverRouteId: dropRouteIds,
                                    forcefullyDrop: false,
                                }
                            }
                        }
                    }
                }
            };

            let dropResponse = await api.post("/api/lazy/manage/data", dropParams);

            if (
                dropResponse.data.data.checkSequenceDriverRoutes &&
                dropResponse.data.data.checkSequenceDriverRoutes.ids.length > 0
            ) {
                let errorData = currentData.filter((d) => {
                    if (dropResponse.data.data.checkSequenceDriverRoutes.ids.includes(parseInt(d.id))) {
                        return true;
                    }
                    return false;
                });

                errorData = errorData.map((d) => {
                    d.driver = dropResponse.data.data.checkSequenceDriverRoutes.message[d.id].driver;
                    d.driverTitle = dropResponse.data.data.checkSequenceDriverRoutes.message[d.id].driverTitle;
                    d.date = dropResponse.data.data.checkSequenceDriverRoutes.message[d.id].date;
                    return d;
                });

                await openErrorDisplayModal(errorData, dropResponse.data.data.checkSequenceDriverRoutes, api);
            }

            dispatch({ type: "CLEAN_CHANGES", payload: { qty: currentData.length } });
            await toast({
                type: "info",
                title: "Reverting changes…",
                content: "You can continue your work while we revert your selected changes.",
            });
            setUpdatedScheduler(updateScheduler + 1);
        } catch (error) {
            console.error({ error });
            console.error("[ERROR]", error.message ? error.message : "");
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAll();
    }, [fetchAll]);

    //let thisStationChanges = 0;
    // if(view == 'loadOut'){
    // thisStationChanges = state.changes;
    /* } else {
        thisStationChanges = state.changes[selectedStation]
        ? state.changes[selectedStation].length
        : 0;
    }*/

    const handleButtonClick = (e) => {
        openDialog();
    };

    const thisStationChanges = state.changes[selectedStation] ? state.changes[selectedStation].length : 0;

    return thisStationChanges > 0 ? (
        <Container
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <PublishButton
                type="primary"
                onClick={handleButtonClick}
            >
                <div style={{ display: "flex", alignItems: 'center' }}>
                    <div>Publish Changes</div>
                </div>
                <Badge>{thisStationChanges}</Badge>
            </PublishButton>
            {menuVisible && (
                <DropdownWrapper style={{
                    [view === 'loadOut' ? 'right' : 'left']: 0
                }}>
                    {options.map((option, index) => (
                        <DropdownItem
                            key={index}
                            value={option.value}
                            className={option.className}
                            onClick={(e) => {
                                handleMenuClick(option.value)
                            }}
                        >
                            <ItemLabel>{option.label}</ItemLabel>
                        </DropdownItem>
                    ))}
                </DropdownWrapper>
            )}
            {visible && (
                <PublishModal
                    visible={visible}
                    setVisible={setVisible}
                    selectedDate={selectedDate}
                    week={week}
                    view={view}
                    dispatch={dispatch}
                    api={api}
                    data={data}
                    updateScheduler={updateScheduler}
                    setUpdatedScheduler={setUpdatedScheduler}
                    fetchAll={fetchAll}
                    selectedStation={selectedStation}
                    isLoading={isLoading}
                    setLoading={setLoading}
                />
            )}
        </Container>
    ) : (
        <Button type="primary" style={{ opacity: 0.5, width: 140 }} disabled={true}>
            No Changes
        </Button>
    );
};

const PublishModal = memo(({ visible, setVisible, selectedDate, week, view, dispatch, api, data,
    updateScheduler, setUpdatedScheduler, fetchAll, selectedStation, isLoading, setLoading }) => {
    const [errorModelVisible, setErrorModelVisible] = useState(false);
    const [publishLoading, setPublishLoading] = useLoading(false);
    const [stations] = useLocalStorage("stations");
    const { register, handleSubmit } = useForm();

    const handleDrop = useCallback(async (formData) => {
        setErrorModelVisible(true);
        const dropIds = formData.publishChanges;
        let dropRouteIds = Array.isArray(dropIds) ? dropIds : [dropIds];
        //perform api call here
        if (dropRouteIds.length > 0) {
            const dropParams = {
                actions: {
                    response: {
                        TempDriverRoute: {
                            custom: {
                                functionName: "removeDropUnpublishChanges",
                                get: "checkSequenceDriverRoutes",
                                excludes: [
                                    "skill",
                                    "vehicle",
                                    "device",
                                    "driver",
                                    "station",
                                    "shiftType",
                                    "schedule",
                                    "status",
                                    "routeId",
                                    "createdBy",
                                    "kickoffLog",
                                    "vehicleDriverRecord",
                                    "oldDriver",
                                    "oldDriverRoute"
                                ],
                                criteria: {
                                    tempDriverRouteId: dropRouteIds,
                                    forcefullyDrop: false,
                                }
                            }
                        }
                    }
                }
            };
            try {
                let response = await api.post("/api/lazy/manage/data", dropParams);
                // window.location.reload();
                //setLoading(true);
                if (
                    response.data.data.checkSequenceDriverRoutes &&
                    response.data.data.checkSequenceDriverRoutes.ids.length > 0
                ) {
                    let errorData = await data.filter((d) => {
                        if (response.data.data.checkSequenceDriverRoutes.ids.includes(parseInt(d.id))) {
                            let index = dropRouteIds.indexOf(d.id.toString());
                            if (index > -1) {
                                dropRouteIds.splice(index, 1);
                            }
                            return true;
                        }
                    });
                    errorData.map((d) => {
                        //   d.changeText = response.data.data.checkSequenceDriverRoutes.message[d.id].message;
                        d.driver = response.data.data.checkSequenceDriverRoutes.message[d.id].driver;
                        d.driverTitle = response.data.data.checkSequenceDriverRoutes.message[d.id].driverTitle;
                        d.date = response.data.data.checkSequenceDriverRoutes.message[d.id].date;
                        return d;
                    });

                    await openErrorDisplayModal(errorData, response.data.data.checkSequenceDriverRoutes, api, dispatch, updateScheduler, setUpdatedScheduler, view);
                }

                dispatch({ type: "CLEAN_CHANGES", payload: { qty: data.length } });
                await toast({
                    type: "info",
                    title: "Reverting changes…",
                    content: "You can continue your work while we revert your selected changes.",
                });
                setUpdatedScheduler(updateScheduler + 1);

                setVisible(false);
                //skip other station driver route display in model
                let res = response.data.data.checkSequenceDriverRoutes;
                if (res?.otherStationDriverRoutes.length > 0) {
                    await openOtherStationDriverRouteDisplayModal(res?.otherStationDriverRoutes, api, dispatch, updateScheduler, setUpdatedScheduler, view);
                }
            } catch (error) {
                console.error({ error });
                console.error("[ERROR]", error.message ? error.message : "");
                alert(error.message);
            }
        }
    });

    const Publish = useCallback(async (publish) => {
        let routeIds = [];
        let smsMessage = {};

        let dataArr = data.filter((item) => !!item.id);
        if (data.length >= 1) {
            dataArr.forEach(function (routeData) {
                if (publish.publishChanges.indexOf(routeData.id.toString()) > -1) {
                    smsMessage[routeData.id] = routeData.notificationText;
                }
            });
        }
        if (data.length > 1) {
            routeIds = publish.publishChanges;

            let dataArr = data.filter((item) => !!item.id);
            let routes = dataArr.filter((shift) => routeIds.indexOf(shift.id.toString()) > -1);

            let routeCount = [];
            let tempRoute = false;
            routes.forEach(function (route) {
                if (route.route_id.toString() in routeCount) {
                    tempRoute = true;
                } else {
                    routeCount[route.route_id.toString()] = 1;
                }
            });
            if (tempRoute === true) {
                alert("You can not publish same route twice");
                return false;
            }
        } else {
            routeIds = [publish.publishChanges];
        }

        if (data.length === 1) {
            routeIds = [publish.publishChanges[0]];
        }
        if (publish.publishChanges.length > 0) {
            setPublishLoading(true);
            const ids = Array.isArray(publish.publishChanges) ? publish.publishChanges : [publish.publishChanges];

            const params = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "publishDriverRoute",
                                get: "publishDriverRoute",
                                excludes: ["skill", "vehicle", "device", "driver", "station", "shiftType", "schedule"],
                                criteria: {
                                    tempDriverRouteIds: ids,
                                    notifyDriver: publish.notify_drivers,
                                    view: view,
                                    smsMessage: smsMessage,
                                },
                            },
                        },
                    },
                },
            };
            try {
                const data = await api.post("/api/lazy/manage/data", params);
                // window.location.reload();

                dispatch({ type: "CLEAN_CHANGES", payload: { qty: data.length } });
                await toast({
                    type: "info",
                    title: "Publish changes…",
                    content: "You can continue your work while we publish your selected changes.",
                });
                setUpdatedScheduler(updateScheduler + 1);
                setVisible(false);
                //skip other station driver route display in model
                let res = data.data.data.publishDriverRoute;
                if(res?.success === true && res?.otherStationDriverRoutes.length > 0) {
                    await openOtherStationDriverRouteDisplayModal(res?.otherStationDriverRoutes, api, dispatch, updateScheduler, setUpdatedScheduler, view);
                }
            } catch (error) {
                console.error({ error });
                console.error("[ERROR]", error.message ? error.message : "");
                alert(error.message);
            }
        }
    });

    useEffect(() => {
        fetchAll();
    }, [fetchAll]);

    const checkPublish = async () => {
        let checkbox = document.querySelector("[name='check_all']").checked;
        if (checkbox == true) {
            document.querySelectorAll("[data-publish='publish']").forEach((item) => {
                item.checked = true;
            });
        } else {
            document.querySelectorAll("[data-publish='publish']").forEach((item) => {
                item.checked = false;
            });
        }
    };

    const DisplayData = ({ data, status, title }) => {
        let labelStatus = false;
        data.map((item, key) => {
            if (item.status == status) {
                labelStatus = true;
            }
        });
        return (
            <>
                {labelStatus && title != "" ? (
                    <ThirdTitle
                        style={{ fontSize: Theme.font.small.size, margin: "0 0 0px 10px", borderBottom: "1px dotted" }}
                    >
                        {title}
                    </ThirdTitle>
                ) : (
                    ""
                )}
                {data.map((item, key) => {
                    return item.status == status ? (
                        <li key={key}>
                            <TableData width="36px">
                                <CheckBox
                                    name={"publishChanges"}
                                    data-publish={"publish"}
                                    ref={register}
                                    options={[{ value: item.id, label: "" }]}
                                />
                            </TableData>
                            {/*<TableData width='92px'>*/}
                            {/*    <Tag>{item.stationCode}</Tag>*/}
                            {/*</TableData>*/}
                            <TableData width="192px">
                                <People name={item.driver} img={item.driver_image} jobTitle={item.driverProfile} />
                            </TableData>
                            <TableData width="340px">{item.changeText}</TableData>
                            <TableData width="230px">
                                <People name={item.user} img={item.user_image} jobTitle={item.userProfile} />
                            </TableData>
                        </li>
                    ) : (
                        ""
                    );
                })}
            </>
        );
    };

    let modalTitle =
        view === "day"
            ? "Publish Changes of: " + moment(selectedDate).format("ddd, DD MMM YYYY")
            : view === "week"
                ? "Publish Changes in Week #" + week
                : view === "loadOut"
                    ? "Publish Changes for: " + moment().format("ddd, DD MMM YYYY")
                    : "Publish Changes";

    let noChanges =
        view === "day"
            ? "No Changes for: " + moment(selectedDate).format("ddd, DD MMM YYYY")
            : view === "week"
                ? "No Changes for Week #" + week
                : view === "loadOut"
                    ? "No Changes for: " + moment().format("ddd, DD MMM YYYY")
                    : "Publish Changes";

    let stationTags = stations
        .filter((s) => [selectedStation].flat().includes(s.id))
        .map((s) => (
            <Spacer inline bottom={2} right={2} key={s.id}>
                <Tag key={s.id}>{s.code}</Tag>
            </Spacer>
        ));

    return (
        <>
            <Modal
                title={
                    <>
                        <Spacer
                            inline
                            right={3}
                            style={{
                                fontWeight: "normal",
                                fontFamily: Theme.font.body,
                                verticalAlign: "text-bottom",
                            }}
                        >
                            <StationSelector showAllOption={false} label={false} onChange={() => setLoading(true)} />
                        </Spacer>
                        {modalTitle}
                    </>
                }
                width="860px"
                visible={visible}
                setVisible={setVisible}
            >
                {publishLoading && <LoadingWrapper><Loading style={{
                                    width: "40px",
                                    padding: 20,
                                    margin: "0 auto",
                                }}/></LoadingWrapper>}
                <form name="new_station">
                    <Table>
                        <li className="header">
                            <TableData width="36px">
                                <CheckBox
                                    name={"check_all"}
                                    title={""}
                                    options={[{ value: "", label: "" }]}
                                    onClick={() => {
                                        checkPublish();
                                    }}
                                />
                            </TableData>
                            {/*<TableData width='92px'>Station</TableData>*/}
                            <TableData width="192px">Driver</TableData>
                            <TableData width="340px">Description</TableData>
                            <TableData width="230px">Made By</TableData>
                        </li>
                        {isLoading && (
                            <Loading
                                style={{
                                    width: "40px",
                                    padding: 20,
                                    margin: "0 auto",
                                }}
                            />
                        )}
                        {!isLoading && data.length > 0 && (
                            <>
                                <DisplayData data={data} status={1} title={""} />
                                <DisplayData data={data} status={2} title={"Swap request by driver"} />
                                <DisplayData data={data} status={3} title={"Drop request by driver"} />
                                <DisplayData data={data} status={4} title={"Shift Request by driver"} />
                            </>
                        )}
                        {!isLoading && !data.length && (
                            <div style={{ textAlign: "center" }}>
                                <Empty />
                                {/*<Text>*/}
                                {/*    <Spacer inline right={2} bottom={2}>Stations:</Spacer>*/}
                                {/*    {stationTags}*/}
                                {/*</Text>*/}
                                {/*<Text>{noChanges}</Text>*/}
                            </div>
                        )}
                        <Spacer top={5} />
                        {!isLoading && data.length > 0 && (
                            <ModalFooter>
                                <Button type="delete" onClick={handleSubmit(handleDrop)}>
                                    Drop
                                </Button>
                                <ModalFooter>
                                    <div className="flex">
                                        <CheckBox
                                            name="notify_drivers"
                                            ref={register}
                                            options={[
                                                {
                                                    label: "Notify Affected Drivers",
                                                    value: "Chat",
                                                },
                                            ]}
                                        />
                                        <Spacer top={1} />
                                        <CheckBox
                                            name="notify_drivers"
                                            ref={register}
                                            disabled={getIsEnableSMS() === 'false' ? true : false}
                                            options={[
                                                {
                                                    label: "Notify Affected Drivers via SMS",
                                                    value: "SMS",
                                                },
                                            ]}
                                        />
                                    </div>
                                    <Spacer inline left={5}>
                                        <Button type="primary" onClick={handleSubmit(Publish)}>
                                            Publish
                                        </Button>
                                    </Spacer>
                                </ModalFooter>
                            </ModalFooter>
                        )}
                    </Table>
                </form>
            </Modal>
        </>
    );
});

const openErrorDisplayModal = (data, dataIds, api) => {
    return dialogPromise((success, cancel) => {
        return <ErrorDisplayModal success={success} cancel={cancel} data={data} dataIds={dataIds} api={api} />;
    });
};

const ErrorDisplayModal = ({ success = () => { }, cancel = () => { }, data, dataIds, api }) => {
    const [visible, setVisible] = useState(true);

    const handleDrop = useCallback(async (formData) => {
        //perform api call here
        if (dataIds.ids.length > 0) {
            const dropParams = {
                actions: {
                    response: {
                        TempDriverRoute: {
                            custom: {
                                functionName: "removeDropUnpublishChanges",
                                get: "checkSequenceDriverRoutes",
                                excludes: [
                                    "skill",
                                    "vehicle",
                                    "device",
                                    "driver",
                                    "station",
                                    "shiftType",
                                    "schedule",
                                    "status",
                                    "routeId",
                                    "createdBy",
                                    "kickoffLog",
                                    "vehicleDriverRecord",
                                    "oldDriver",
                                    "oldDriverRoute",
                                ],
                                criteria: {
                                    tempDriverRouteId: dataIds.ids,
                                    forcefullyDrop: true,
                                },
                            },
                        },
                    },
                },
            };
            try {
                let response = await api.post("/api/lazy/manage/data", dropParams);
                success();
            } catch (error) {
                console.error({ error });
                console.error("[ERROR]", error.message ? error.message : "");
                alert(error.message);
            }
        }
    });

    return (
        <Modal title={"Warning"} width="860px" visible={true} setVisible={cancel}>
            <Alert.Warning
                title={<b>Shift created for 7th consecutive day.</b>}
                content={
                    "7th straight days shift criteria matched up with this shift and its not published yet. So now if you drop this change then its creating 7th straight days and that puts your station at risk of being in violation of Amazon's Work Hour Compliance rule."
                }
            />
            <Table>
                <li className="header">
                    <TableData width="192px">Driver</TableData>
                    <TableData width="340px">Unpublish Changes</TableData>
                    <TableData width="230px">Date</TableData>
                </li>
                {data &&
                    data.length > 0 &&
                    data.map((item, key) => {
                        return (
                            <li key={key}>
                                <TableData width="192px">
                                    <People name={item.driver} img={item.driver_image} jobTitle={item.driverProfile} />
                                </TableData>
                                <TableData width="340px">{item.changeText}</TableData>
                                <TableData width="230px">{item.date}</TableData>
                            </li>
                        );
                    })}

                <Spacer top={5} />
                <ModalFooter>
                    <Button type="delete" onClick={handleDrop}>
                        Drop
                    </Button>
                </ModalFooter>
            </Table>
        </Modal>
    );
};

const openOtherStationDriverRouteDisplayModal = (data, api, dispatch, updateScheduler, setUpdatedScheduler, view) => {
    return dialogPromise((success, cancel) => {
        return (
            <OtherStationDriverRouteDisplayModal
                success={success}
                cancel={cancel}
                data={data}
                api={api}
                dispatch={dispatch}
                updateScheduler={updateScheduler}
                setUpdatedScheduler={setUpdatedScheduler}
                view={view}
            />
        );
    });
};

const OtherStationDriverRouteDisplayModal = ({
    success = () => {},
    cancel = () => {},
    data,
    api,
    dispatch,
    updateScheduler,
    setUpdatedScheduler,
    view
}) => {
    const [visible, setVisible] = useState(true);

    const handleGhostShift = async () => {
        // perform api call here
        if (data.length > 0) {
            const params = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "ghostShiftRecall",
                                get: "ghostShiftRecall",
                                excludes: [],
                                includes: [],
                                criteria: {
                                    ghostShift: data,
                                    view: view
                                },
                            },
                        },
                    },
                },
            };
            try {
                let response = await api.post("/api/lazy/manage/data", params);
                dispatch({ type: "CLEAN_CHANGES", payload: { qty: response.length } });
                await toast({
                    type: "info",
                    title: "Publish changes…",
                    content: "You can continue your work while we publish your selected changes.",
                });
                setUpdatedScheduler(updateScheduler + 2);
                setVisible(false);
                success();
            } catch (error) {
                console.error({ error });
                console.error("[ERROR]", error.message ? error.message : "");
                alert(error.message);
            }
        }
    };

    return (
        <Modal title={"Ghost shift other station"} width="860px" visible={true} setVisible={cancel}>
            <Alert.Info
                title={<b>Some driver shift is other station.</b>}
                content={"It's below shift list is other station. Not publish shift."}
                useIcon={true}
            />
            <Table>
                <TableHeader headers={[ { width: "160px", label: "Driver Name" }, { width: "550px", label: "Description" }, { width: "80px", label: "Station" } ]} />
                {data &&
                    data.length > 0 &&
                    data.map((item, key) => {
                        return (
                            <TableRow key={key}>
                                <TableData width="160px">{item.driverName}</TableData>
                                <TableData width="550px">{item.description}</TableData>
                                <TableData width="80px">{item.stationCode}</TableData>
                            </TableRow>
                        );
                    })}

                <Spacer top={5} />
                <ModalFooter>
                    <Alert.Warning
                        title={""}
                        content={
                            "Are you sure want to create ghost shift?"
                        }
                        useIcon={true}
                    />
                    <ModalFooter>
                        <Spacer inline right={5}>
                            <Button type="primary" onClick={handleGhostShift}>
                                Create Ghost
                            </Button>
                        </Spacer>
                    </ModalFooter>
                </ModalFooter>
            </Table>
        </Modal>
    );
};
export default PublishPopUp;
