import React, { useEffect, useRef, useState } from 'react'
import styled from "styled-components";
import { Grid, InputName, Text } from '../styles';
import { Avatar, CloseIcon, Modal, PinIcon, useChatContext } from 'stream-chat-react';
import { CustomDropdown, Icon, Toggle, async, Theme, Button} from '@dspworkplace/ui';
import { useDispatch, useTrackedState } from '../store';
import { AvatarCustome, ContainerDot, getInitials } from '../ChannelIcon';
import Tag from "../../components/Tag";
import { upload, getImpersonateStreamChatIdentifier, getUserId, getStreamChatIdentifier,getRole } from '../../Auth';
import moment from 'moment';
import { getItemFromStorage } from '../utils';
import { engine, getChatUserRole } from '../../Utilities';
import { toast } from "../../components/Toasts";
import { mimeTypes } from "../../patterns";
import { alert } from '../../components/Alert';
import {confirm} from "../../components/Confirm";
import Timeline from "../Components/Timeline.js";
import { useModal } from "../../components/Modal";
import DatePicker from '../../components/Calendar/picker';
import { useForm } from 'react-hook-form';

function ChannelDetails({ isShowChannelDetail, setShowChannelDetail, channel, isPage, setOpenPinnedList, setUpdateModalOPen, setShowCreatePoll, setShowPoll, allowGroupActions, isDisableOnImpersonate }) {
    const { chatClient, initialChannel } = useTrackedState()
    const dispatch = useDispatch();
    const { setActiveChannel } = useChatContext();

    const allMembers = Object.values(channel?.state?.members);
    const [members, setMembers] = useState([]);
    const [mute, setMute] = useState(false);
    const [pin, setPin] = useState(false);
    const [administrativelyPin, setAdministrativelyPin] = useState(false);
    const [isArchive, setArchive] = useState(false);
    const [isEditNameOpen, setEditNameOpen] = useState(false)
    const [isEditDescriptionOpen, setEditDescriptionOpen] = useState(false)
    const [groupName, setGroupName] = useState(channel?.data?.name)
    const inputRef = useRef(null);
    const [imageLoading, setImageLoading] = useState(false)
    const [isHover, setHover] = useState(false)
    const [popUpMenu, setPopUpMenu] = React.useState(false);
    const [groupDescription, setDescription] = useState(channel?.data?.description);
    const [isVisible, setVisible] = useState(false)
    const [isOpenRoleEdit, setOpenRoleEdit] = useState(false)
    const [roles, setRoles] = useState([])
    const [selectedRolse, setRoleSelection] = useState(channel?.data?.read_only_roles)
    const [mute_all, setMuteAll] = useState(false);
    const [filters, setFilters] = useState([]);
    const [timelineVisible, toggleTimeline] = useModal(false);
    const [isExport, setIsExport] = useState(false);
    const { register, handleSubmit, watch, errors, getValues } = useForm();
    const [selectedDate, setSelectedDate] = useState({start:moment().format('YYYY-MM-DD'),end:moment().format('YYYY-MM-DD')});

    var name
    var image
    var status

    if (channel.type == 'duo') {
        Object.keys(channel.state.members).map((member) => {
            if (member != chatClient.userID && member != 'bot') {
                name = channel.state.members[member].user.name;
                image = channel.state.members[member].user.image;
                status = channel.state.members[member].user.online;
            }
        });
    }

    useEffect(() => {
        if (channel) {
            getMembers()
            getFilterResult();
            setPin(channel?.data?.pinUser && channel?.data?.pinUser?.includes(chatClient?.userID))
            setAdministrativelyPin(channel?.data?.isAdministrarivelyPin)
            setMute(channel?.muteStatus().muted)
            setArchive(channel?.data?.isArchive)
            setMuteAll(channel?.data?.mute_for_all)
        }
    }, [channel])

    useEffect(() => {
        const abortController = new AbortController();
        if (chatClient) {
            let currentRoles = getItemFromStorage('roles');
            if (Array.isArray(currentRoles)) {
                setRoles(currentRoles);
            }
        }
        return () => {
            abortController.abort();
        };
    }, [chatClient]);
    async function getMembers() {
        let offset = 0;
        let limit = 50;
        let hasData = true;
        while (hasData) {
            let data = await getMembersData(offset, limit)

            if (data.length > 0) {
                await setMembers((prevState) => [...prevState, ...data])
                offset = offset + limit
            } else {
                hasData = false
            }
        }
    }

    const getFilterResult = async () => {
        const filterParams = {
            actions: {
                response: {
                    "TwilioChannel": {
                        custom: {
                            get: "channel",
                            functionName: "findOneBySid",
                            criteria: {
                                sid: channel.id
                            },
                            includes: {
                                0: "id",
                                1: "filters"
                            }
                        }
                    }
                }
            }
        };

        await engine().post("/api/lazy/manage/data", filterParams).then(result => {
            if (result) {
                if (result?.data?.data?.channel) {
                    setFilters(result?.data?.data?.channel);
                }
            }
        });
    };

    const setArchiveOrUnarchiveGroup = async (sid, type) => {
        const params = {
            actions: {
                response: {
                    "TwilioChannel": {
                        custom: {
                            get: "channel",
                            functionName: "channelArchiveOrUnarchive",
                            criteria: {
                                sid: sid,
                                case: type
                            },
                        }
                    }
                }
            }
        };

        await engine().post("/api/lazy/manage/data", params);
    };

    async function getMembersData(offset, limit) {
        const data = await channel.queryMembers({}, { created_at: -1 }, { limit: limit, offset: offset });
        return data?.members
    }

    const muteChannel = async () => {
        if (channel?.muteStatus().muted) {
            await channel.unmute();
        } else {
            await channel?.mute();
        }
        await channel?.watch();
        await setMute(channel?.muteStatus().muted)
    };

    const pinChannel = async () => {
        let pinUser = await channel?.data?.pinUser || [];
        if (pin) {
            if (pinUser?.includes(chatClient.userID)) {
                await pinUser?.splice(pinUser?.indexOf(chatClient.userID), 1);
            }
        } else {
            if (!pinUser?.includes(chatClient.userID)) {
                await pinUser?.push(chatClient.userID);
            }
        }
        await channel?.updatePartial({ set: { pinUser: pinUser } });
        await setPin((channel?.data?.pinUser && channel?.data?.pinUser?.includes(chatClient?.userID)) || false)
    };

    async function onAdministrativelyPinChannel() {
        let pinUser = channel?.data?.pinUser || [];
        let isAdministrativelyPinChannel = false
        if (channel?.data?.isAdministrarivelyPin) {
            // pinUser.splice(pinUser.indexOf(chatClient.userID), 1);
            pinUser = []
            isAdministrativelyPinChannel = false
            setAdministrativelyPin(false)
            setPin(false)
        } else {
            pinUser = members?.map(i => { return i?.user?.id })
            isAdministrativelyPinChannel = true
            setAdministrativelyPin(true)
        }

        channel.updatePartial({ set: { pinUser: pinUser, isAdministrarivelyPin: isAdministrativelyPinChannel } });
        channel.watch();
    };

    const muteAllChannelUser = async () => {
        await channel?.updatePartial({ set: { mute_for_all: !mute_all } });
        await setMuteAll(channel?.data?.mute_for_all)
    };

    async function archiveGroup() {
        const confirmation = await confirm('Are you sure you want to archive this group for all users?');
        if (!confirmation) {
            return;
        }
        await channel?.updatePartial({ set: { isArchive: true } });
        await setArchiveOrUnarchiveGroup(channel?.data?.id, 'archive');
        await setActiveChannel(initialChannel)
        await setShowChannelDetail(false)
        await setArchive(channel?.data?.isArchive)
    }

    async function unArchiveGroup() {
        const confirmation = await confirm('Are you sure you want to unarchive this group for all users?');
        if (!confirmation) {
            return;
        }
        await channel?.updatePartial({ set: { isArchive: false } });
        await setArchiveOrUnarchiveGroup(channel?.data?.id, 'unarchive');
        await setArchive(channel?.data?.isArchive)
    }

    async function onChangeGroupName(e) {
        e.preventDefault();
        await channel.updatePartial({ set: { name: groupName } });
        await setEditNameOpen(false);
    }

    async function onChangeGroupDescription(e) {
        e.preventDefault();
        await channel.updatePartial({ set: { description: groupDescription } });
        await setEditDescriptionOpen(false);
    }

    const handleClick = () => {
        // 👇️ open file input box on click of another element
        inputRef.current.click();
    };

    const handleFileChange = async event => {
        const fileObj = event?.target?.files && event?.target?.files[0];
        if (fileObj) {
            try {
                await setImageLoading(true);
                if(mimeTypes["images"].includes(fileObj?.type)){
                    let formData = new FormData();  
                    formData.append('image', fileObj);
                    formData.append('name', fileObj?.name);
                    var result = await upload(formData);
                    await channel.updatePartial({ set: { image: result?.data?.ObjectURL } });
                    await setImageLoading(false)
                } else {
                    alert({ text: "Failed to upload profile photo.! Upload jpg, png image format.", btnText: "Cancel" });
                    setImageLoading(false)
                    return false;
                }
                
            } catch (error) {
                alert({ text: "Failed to upload profile photo..!", btnTxt: "Cancel" });
                setImageLoading(false)
            }
        }
    };

    async function handelPopupClick(event, actionType) {
        event.stopPropagation();
        if (actionType === "remove_photo")
            await channel.updatePartial({ set: { image: '' } });
        if (actionType === "view_photo")
            setVisible(true)
        if (actionType === "upload_photo")
            handleClick()

    }

    function PopUpMenu() {
        if (channel.type === "group") {
            return (
                <ul className="drop-down-menu" style={{ margin: 0, padding: 0 }} onMouseLeave={() => setPopUpMenu(false)} >
                    {channel?.data?.image ? <li onClick={(e) => handelPopupClick(e, "view_photo")} >View Photo</li> : null}
                    {(<li onClick={(e) => handelPopupClick(e, "upload_photo")}>Upload Photo</li>)}
                    {(<li onClick={(e) => handelPopupClick(e, "remove_photo")}>Remove Photo</li>)}
                </ul >
            )
        }
        if (channel?.type === "duo" && image) {
            return (
                <ul className="drop-down-menu" style={{ margin: 0, padding: 0 }} onMouseLeave={() => setPopUpMenu(false)} >
                    <li onClick={(e) => handelPopupClick(e, "view_photo")} >View Photo</li>
                </ul >
            );
        }
    }

    async function updatereadOnlyRoles() {
        try {
            await channel.updatePartial({ set: { read_only_roles: selectedRolse } });
            await setOpenRoleEdit(false)
        } catch (e) {
            setOpenRoleEdit(false)
        }
    }

    async function reportAbuse(e) {
        let userId;
        Object.keys(channel.state.members).map((member) => {
            if (member != chatClient.userID) {
                userId = channel.state.members[member].user.id;
            }
        });
        await engine().post('/api/stream/report-abuse', {
            userId: userId
        })
    }

    async function exportChat(e) {
        let start = moment(channel.data.created_by.created_at).format("YYYY-MM-DD");
        let isoString = moment().toISOString();
        let end = moment(isoString).format("YYYY-MM-DD");
        let type = channel.data.type;
        let id = channel.data.id;
        let currentUser = getStreamChatIdentifier();
        let sendUserId = getUserId();
        const dateRangeArr = e.dates.split(" – ");
        const startDate = dateRangeArr[0];
        const endDate = dateRangeArr[1];
        const response = await engine().post('/api/stream/channel/export', {
            id: id,
            currentUser: currentUser,
            sendUserId: sendUserId,
            startDate: startDate,
            endDate: endDate
        });
        if (response.data.success) {
            toast({
                type: "Success",
                title: response.data.message,
                content: "",
                timeout: 1000
            });
        }
    }

    return (
        <div style={{ display: isShowChannelDetail ? 'flex' : 'none', width: isPage ? '500px' : '315px', borderLeft: 'solid 1px #EAF0F6', height: "100%", overflowX: "hidden", overflowY: "auto" }} >
            <Container>
                <HeaderContainer>
                    <div style={{ cursor: 'pointer', }} onClick={() => setShowChannelDetail(false)} >
                        <CloseIcon />
                    </div>
                    <Text color={'#000000'} fontWeight={500} margin="15px" >
                        {channel?.type === "group" ? 'Group Info' : 'Info'}</Text>
                </HeaderContainer>

                <ItemContainer style={{ justifyContent: 'center', alignItems: 'center', display: 'grid', }} >
                    <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        accept={"image/jpeg,image/png,image/gif"}
                        onChange={handleFileChange}
                    />
                    <ImageChannel
                        // onClick={handleClick}
                        onClick={() => setPopUpMenu(true)}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)} >
                        {image || channel?.data?.image ?
                            <Avatar image={channel.type === 'duo' ? image : channel?.data?.image} name={channel.type === "duo" ? name : channel?.data.name} size={150} />
                            :
                            <AvatarCustome size={"150px"} fontSize={'40px'} >
                                {getInitials(channel.type === "duo" ? name : channel?.data.name)?.toUpperCase()}
                            </AvatarCustome>
                        }
                        {imageLoading &&
                            <div style={{
                                width: '22px', height: '22px', border: '4px solid #f3f3f3', borderTop: '4px solid #f9a94d', borderRadius: '50%',
                                animation: 'spinner 1.5s linear infinite', position: 'absolute', right: '40%', top: '44%'
                            }} />
                        }
                        {isHover ?
                            (image === undefined || image === '' || channel?.data?.image === undefined || channel?.data?.image === '') ?
                                null
                                :
                                <IconHover>
                                    <svg fill="#ffffff" height="32px" width="64px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 390 390" space="preserve"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_17_"> <path id="XMLID_18_" d="M365,95h-70.855l-15.1-40.267C276.85,48.878,271.253,45,265,45H125c-6.253,0-11.85,3.878-14.045,9.733 L95.855,95H25c-13.807,0-25,11.193-25,25v200c0,13.807,11.193,25,25,25h340c13.807,0,25-11.193,25-25V120 C390,106.193,378.807,95,365,95z M195,125c52.383,0,95,42.617,95,95s-42.617,95-95,95s-95-42.617-95-95S142.617,125,195,125z"></path> <path id="XMLID_21_" d="M130,220c0,35.841,29.159,65,65,65s65-29.159,65-65s-29.159-65-65-65S130,184.159,130,220z"></path> </g> </g></svg>
                                    {channel?.type === "group" && (<Text color="#ffffff" height="0" width="auto" >Change Group Icon</Text>)}
                                </IconHover>
                            : null
                        }
                    </ImageChannel>

                    {popUpMenu && PopUpMenu()}

                    {isEditNameOpen && channel?.type === "group" ?
                        <form onSubmit={onChangeGroupName} >
                            <InputName
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                autoFocus
                            />
                        </form>
                        :
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text margin={'17px 7px 17px'} textAlign="center" fontWeight={"bold"} fontSize={'16px'} >
                                {channel?.type === "group" ? channel?.data.name : name}</Text>
                            {channel?.type === "group" && (
                                <Icon.Edit size="20px" style={{ cursor: 'pointer' }} onClick={() => setEditNameOpen(true)} />
                            )}
                        </div>
                    }

                    {status && (
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }} >
                            <ContainerDot status={status} style={{ position: 'relative', right: '5px', }} />
                            <Text>Online</Text>
                        </div>
                    )}
                </ItemContainer>

                {/* group description */}
                {channel?.type === "group" && (
                    <ItemContainer margin={'2px 0'} >
                        {channel?.data?.description ?
                            <Text margin={'0 0 10px'} color='#7C98B6' >Group Description</Text>
                            :
                            <Text
                                margin={'0 0 10px'} color='#7C98B6'
                                fontWeight="bold"
                                fontSize="16px"
                                onClick={() => setEditDescriptionOpen(true)}
                                style={{ cursor: 'pointer' }}
                            >Add Group Description</Text>
                        }
                        {isEditDescriptionOpen ?
                            <form onSubmit={onChangeGroupDescription} style={{ display: 'flex' }} >
                                <InputName
                                    value={groupDescription}
                                    onChange={(e) => setDescription(e.target.value)}
                                    autoFocus
                                />
                                <div onClick={() => {
                                    setDescription(channel?.data?.description)
                                    setEditDescriptionOpen(false)
                                }} style={{ cursor: 'pointer' }} >
                                    <CloseIcon />
                                </div>
                            </form>
                            : channel?.data?.description ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <Text fontSize={'16px'} margin="0 5px 0 0" >{channel?.data?.description}</Text>
                                    {(<Icon.Edit size="20px" style={{ cursor: 'pointer' }} onClick={() => setEditDescriptionOpen(true)} />)}
                                </div>
                                :
                                null
                        }
                        <Text
                            color='darkgray'
                            fontSize={'14px'} margin="15px 0 0 0" >
                            {`Group created by ${channel?.data?.created_by?.name} at ${moment(channel?.data?.created_at).format("DD MMM YYYY hh:MM A")}`}</Text>
                    </ItemContainer>
                )}

                {/* read only group */}
                {channel?.type === "group" && getChatUserRole() !== 'ROLE_DELIVERY_ASSOCIATE' && (
                    <ItemContainer margin={'2px 0'} >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Text color='#7C98B6' >Groups selected below can Read Only</Text>
                            <Icon.Edit size="20px" style={{ cursor: 'pointer' }} onClick={() => setOpenRoleEdit(true)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {channel?.data?.read_only_roles?.map((item, index) => {
                                return (
                                    <div style={{ padding: '3px 8px', borderRadius: '3px', margin: '3px', backgroundColor: '#EAF0F6' }}>
                                        <Text>{item}</Text>
                                    </div>
                                )
                            })}
                        </div>
                        {isOpenRoleEdit && (
                            <Grid templateColumns="70% 30%" borderRadius="7px" style={{ marginTop: '10px' }} columnGap="5px" >
                                {/* <Input type="search" name="channelName" label="Name" ref={inputRef}/> */}
                                <CustomDropdown
                                    name='permissions'
                                    placeholder='Groups selected below can Read Only'
                                    // ref={dropdownRef}
                                    // placeholder='All'
                                    multiple={true}
                                    options={roles.map(e => ({ name: e.roleName, value: e.roleName }))}
                                    // size={'big'}
                                    defaultValue={selectedRolse}
                                    onChange={(e) => {
                                        setTimeout(() => {
                                            setRoleSelection(e)
                                        }, 50)
                                    }}
                                    style={{ width: 'auto' }}
                                />
                                <div style={{ backgroundColor: '#0071BC', display: 'flex', justifyContent: 'center', borderRadius: '3px', padding: '4px', cursor: 'pointer', height: '-webkit-fill-available' }}
                                    onClick={() => updatereadOnlyRoles()}  >
                                    <Text color='#ffffff'>Update</Text>
                                </div>
                            </Grid>
                        )}
                    </ItemContainer>
                )}

                <ItemContainer margin={'2px 0'} >
                    <Item>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >
                            <div style={{ height: '20px', width: '20px' }} >
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='#7C98B6'><title>volume-glyph</title><path d="M234.67,106.67V405.33A21.33,21.33,0,0,1,200,422L99.18,341.33H64a64.07,64.07,0,0,1-64-64V234.67a64.07,64.07,0,0,1,64-64H99.18L200,90a21.33,21.33,0,0,1,34.66,16.66Zm54.11,90.68a21.33,21.33,0,0,0-1.19,30.15,42.23,42.23,0,0,1,0,57,21.33,21.33,0,1,0,31.33,29,84.89,84.89,0,0,0,0-114.94A21.34,21.34,0,0,0,288.78,197.34ZM379,137.87a21.33,21.33,0,0,0-30.78,29.54,127.61,127.61,0,0,1,0,177.19A21.33,21.33,0,1,0,379,374.14a170.26,170.26,0,0,0,0-236.27Zm60.36-60.6a21.34,21.34,0,0,0-30.55,29.79,213.59,213.59,0,0,1,0,297.9,21.34,21.34,0,0,0,30.55,29.79,256.28,256.28,0,0,0,0-357.48Z" /></svg>
                            </div>
                            <Text margin='12px' fontSize={'16px'} >
                                Mute Group</Text>
                        </div>
                        <Toggle
                            name={'mute'}
                            onChange={() => muteChannel()}
                            on={mute}
                        />
                    </Item>

                    {channel?.type === 'group' && (
                        <Item>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >
                                <div style={{ height: '19px', width: '19px' }} >
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='#7C98B6'><title>volume-glyph</title><path d="M234.67,106.67V405.33A21.33,21.33,0,0,1,200,422L99.18,341.33H64a64.07,64.07,0,0,1-64-64V234.67a64.07,64.07,0,0,1,64-64H99.18L200,90a21.33,21.33,0,0,1,34.66,16.66Zm54.11,90.68a21.33,21.33,0,0,0-1.19,30.15,42.23,42.23,0,0,1,0,57,21.33,21.33,0,1,0,31.33,29,84.89,84.89,0,0,0,0-114.94A21.34,21.34,0,0,0,288.78,197.34ZM379,137.87a21.33,21.33,0,0,0-30.78,29.54,127.61,127.61,0,0,1,0,177.19A21.33,21.33,0,1,0,379,374.14a170.26,170.26,0,0,0,0-236.27Zm60.36-60.6a21.34,21.34,0,0,0-30.55,29.79,213.59,213.59,0,0,1,0,297.9,21.34,21.34,0,0,0,30.55,29.79,256.28,256.28,0,0,0,0-357.48Z" /></svg>
                                </div>
                                <Text margin='12px' fontSize={'16px'} >
                                    Mute Channel Notification For All Users</Text>
                            </div>
                            <Toggle
                                name={"muteAll"}
                                onChange={() => muteAllChannelUser()}
                                on={mute_all} />
                        </Item>
                    )}
                    {administrativelyPin !== true && (
                        <Item>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >
                                <div style={{ height: '19px', width: '19px' }} >
                                    <svg fill="#7C98B6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.879 122.867" ><g><path fillRule="evenodd" clipRule="evenodd" d="M83.88,0.451L122.427,39c0.603,0.601,0.603,1.585,0,2.188l-13.128,13.125 c-0.602,0.604-1.586,0.604-2.187,0l-3.732-3.73l-17.303,17.3c3.882,14.621,0.095,30.857-11.37,42.32 c-0.266,0.268-0.535,0.529-0.808,0.787c-1.004,0.955-0.843,0.949-1.813-0.021L47.597,86.48L0,122.867l36.399-47.584L11.874,50.76 c-0.978-0.98-0.896-0.826,0.066-1.837c0.24-0.251,0.485-0.503,0.734-0.753C24.137,36.707,40.376,32.917,54.996,36.8l17.301-17.3 l-3.733-3.732c-0.601-0.601-0.601-1.585,0-2.188L81.691,0.451C82.295-0.15,83.279-0.15,83.88,0.451L83.88,0.451z" /></g></svg>
                                </div>
                                <Text margin='12px' fontSize={'16px'} >
                                    Pin</Text>
                            </div>
                            <Toggle
                                name={"pin"}
                                onChange={() => pinChannel()}
                                on={pin} />
                        </Item>
                    )}
                    {channel?.type === 'group' && (
                        <Item>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >
                                <div style={{ height: '19px', width: '19px' }} >
                                    <svg fill="#7C98B6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.879 122.867" ><g><path fillRule="evenodd" clipRule="evenodd" d="M83.88,0.451L122.427,39c0.603,0.601,0.603,1.585,0,2.188l-13.128,13.125 c-0.602,0.604-1.586,0.604-2.187,0l-3.732-3.73l-17.303,17.3c3.882,14.621,0.095,30.857-11.37,42.32 c-0.266,0.268-0.535,0.529-0.808,0.787c-1.004,0.955-0.843,0.949-1.813-0.021L47.597,86.48L0,122.867l36.399-47.584L11.874,50.76 c-0.978-0.98-0.896-0.826,0.066-1.837c0.24-0.251,0.485-0.503,0.734-0.753C24.137,36.707,40.376,32.917,54.996,36.8l17.301-17.3 l-3.733-3.732c-0.601-0.601-0.601-1.585,0-2.188L81.691,0.451C82.295-0.15,83.279-0.15,83.88,0.451L83.88,0.451z" /></g></svg>
                                </div>
                                <Text margin='12px' fontSize={'16px'} >
                                    Administratively Pin</Text>
                            </div>
                            <Toggle
                                name={"pin"}
                                onChange={() => onAdministrativelyPinChannel()}
                                on={pin} />
                        </Item>
                    )}

                    <Item>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => {
                                setShowChannelDetail(false)
                                setShowCreatePoll(false)
                                setShowPoll(false)
                                setOpenPinnedList(true)
                            }}  >
                            <div style={{ height: '19px', width: '19px' }} >
                                <svg fill="#7C98B6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.879 122.867" ><g><path fillRule="evenodd" clipRule="evenodd" d="M83.88,0.451L122.427,39c0.603,0.601,0.603,1.585,0,2.188l-13.128,13.125 c-0.602,0.604-1.586,0.604-2.187,0l-3.732-3.73l-17.303,17.3c3.882,14.621,0.095,30.857-11.37,42.32 c-0.266,0.268-0.535,0.529-0.808,0.787c-1.004,0.955-0.843,0.949-1.813-0.021L47.597,86.48L0,122.867l36.399-47.584L11.874,50.76 c-0.978-0.98-0.896-0.826,0.066-1.837c0.24-0.251,0.485-0.503,0.734-0.753C24.137,36.707,40.376,32.917,54.996,36.8l17.301-17.3 l-3.733-3.732c-0.601-0.601-0.601-1.585,0-2.188L81.691,0.451C82.295-0.15,83.279-0.15,83.88,0.451L83.88,0.451z" /></g></svg>
                            </div>
                            <Text margin='12px' fontSize={'16px'} >
                                Pinned Messages</Text>
                        </div>
                        <Icon.ArrowRight size='15px' fill={'#7C98B6'} />
                    </Item>
                    {channel?.type === 'group' && (
                        <Item
                            onClick={() => setUpdateModalOPen(true)}
                            // onClick={() => dispatch({ type: "OPEN_EDIT_CHANNEL_MODAL", isNewChannel: false, currentChannel: channel })}
                            style={{ cursor: 'pointer' }} >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <div style={{ height: '20px', width: '20px' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="settings" fill='#7C98B6'><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"></path></svg>
                                </div>
                                <Text margin='12px' fontSize={'16px'} >
                                    Update Members</Text>
                            </div>
                            {/* <Text fontSize='13px' >In progress</Text> */}
                            <Icon.ArrowRight size='15px' fill={'#7C98B6'} />
                        </Item>
                    )}
                    {(getRole() === 'ROLE_OWNER' || getRole() === 'ROLE_OPERATIONS_MANAGER' || getRole() === 'ROLE_OPERATIONS_ACCOUNT_MANAGER' || getRole() === 'ROLE_HR_ADMINISTRATOR') && (channel?.type === 'group' || channel?.type === 'duo') && (
                        <form onSubmit={handleSubmit(exportChat)} >
                            <Item
                                onClick={() => setIsExport(!isExport)}
                                style={{ cursor: 'pointer' }} 
                            >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                    {/* <div style={{ height: '20px', width: '20px' }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="settings" fill='#7C98B6'><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"></path></svg>
                                    </div> */}
                                    <Icon.ExportChat size="20px" color={"#7C98B6"} />
                                    <Text margin='12px' fontSize={'16px'} >
                                        Export Chat</Text>
                                </div>
                                <Icon.ArrowRight size='15px' fill={'#7C98B6'} />
                            </Item>
                            {isExport == true && <>
                                <Item>
                                    <DatePicker
                                        ref={
                                            register({
                                                required: "Field Required",
                                                validate: {
                                                    exists: async (date) => {
                                                        const dateRangeArr = date.split(" – ");
                                                        const startDate = dateRangeArr[0]
                                                            ? moment.utc(dateRangeArr[0])
                                                            : "";
                                                        const endDate = dateRangeArr[0]
                                                            ? moment.utc(dateRangeArr[1])
                                                            : "";

                                                        if (endDate.diff(startDate, "days") > 90) {
                                                            return "Please Select Date Range maximum of 90 days";
                                                        }

                                                        return true;
                                                    },
                                                },
                                            })
                                        }
                                        name='dates'
                                        label='Date Range (Select Multiple Days)'
                                        range='date'
                                        onChange={date => {
                                            if (date) {
                                                const dateRangeArr = date.split(' – ');
                                                const startDate = (dateRangeArr[0]) ? moment.utc(dateRangeArr[0]) : '';
                                                const endDate = (dateRangeArr[0]) ? moment.utc(dateRangeArr[1]) : '';
                                                if (startDate.isValid() && endDate.isValid()) {
                                                    setSelectedDate({
                                                        start: startDate.format('YYYY-MM-DD'),
                                                        end: endDate.format('YYYY-MM-DD')
                                                    });
                                                }
                                            }
                                        }}
                                        disableDates={{
                                            after: new Date(),
                                            before: new Date(channel?.data?.created_at)
                                        }}
                                        defaultValues={[new Date(selectedDate.start), new Date(selectedDate.end)]}
                                        error={errors.dates && errors.dates.message}
                                    />
                                </Item>
                                <Item>
                                    <Button type="primary" style={{"marginTop": "15px"}}>Export</Button>
                                </Item>
                            </>}
                        </form>
                    )}
                </ItemContainer>

                {/* members list */}
                {channel?.type === 'group' && (
                    <ItemContainer margin={'2px 0'} >
                        {getChatUserRole() !== 'ROLE_DELIVERY_ASSOCIATE' && (
                            <div>
                                <Text margin={'0 0 5px'} color='#7C98B6' >Channel Filters</Text>
                                <div style={{ height: "auto" }} >
                                    <Tag options={filters} />
                                </div>
                            </div>
                        )}
                        <Text margin={'15px 0 5px'} color='#7C98B6' >
                            {`${members?.length} Members`}</Text>
                        <div style={{ overflowY: 'auto', height: '29vh' }} >
                            {members.map((member, index) => {
                                if (!member.user?.id) return null;
                                return (
                                    <Item style={{ justifyContent: 'flex-start', marginBottom: '5px' }} key={index} >
                                        <div style={{ position: 'relative' }} >
                                            <Avatar image={member?.user.image} name={member?.user.name} size={32} />
                                            <ContainerDot status={member?.user?.online} />
                                        </div>
                                        <Text margin='10px 0 10px 13px' fontSize="15px" >{member?.user.name}</Text>
                                    </Item>
                                )
                            })}
                        </div>
                    </ItemContainer>
                )}

                {!isDisableOnImpersonate && allowGroupActions && channel?.type === 'group' && (
                    <ItemContainer margin={'2px 0'} >
                        {channel?.data.isArchive ?
                            <Item style={{ cursor: 'pointer' }} onClick={() => unArchiveGroup()} >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                    <div style={{ height: '25px', width: '25px' }} >
                                        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"><defs><path d="M0 0h48v48H0V0z" id="a"></path></defs><clipPath id="b"><use overflow="visible" href="#a"></use></clipPath><path clip-path="url(#b)" d="m41.09 10.45-2.77-3.36A3.043 3.043 0 0 0 36 6H12c-.93 0-1.76.43-2.31 1.09l-2.77 3.36C6.34 11.15 6 12.03 6 13v25c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V13c0-.97-.34-1.85-.91-2.55zM24 19l11 11h-7v4h-8v-4h-7l11-11zm-13.75-9 1.63-2h24l1.87 2h-27.5z" fill="#d94c54" class="fill-000000"></path></svg>
                                    </div>
                                    <Text margin='15px' fontSize={'16px'} color="#D94C54" >
                                        Unarchive Group</Text>
                                </div>
                            </Item>
                            :
                            <Item style={{ cursor: 'pointer' }} onClick={() => archiveGroup()} >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                    <div style={{ height: '20px', width: '20px' }} >
                                        <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 2.2 16.1.5C15.9.2 15.5 0 15 0H3c-.5 0-.9.2-1.2.5L.5 2.2C.2 2.6 0 3 0 3.5V16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3.5c0-.5-.2-.9-.5-1.3ZM9 14.5 3.5 9H7V7h4v2h3.5L9 14.5ZM2.1 2l.8-1h12l.9 1H2.1Z" fill="#d94c54" fillRule="evenodd" className="fill-000000"></path></svg>
                                    </div>
                                    <Text margin='15px' fontSize={'16px'} color="#D94C54" >
                                        Archive Group</Text>
                                </div>
                            </Item>
                        }
                        {/* Channel History */}
                        <Item style={{ cursor: 'pointer' }} onClick={() => toggleTimeline(false)} >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <div style={{ height: '20px', width: '20px' }} >
                                    <Icon.TimeLine size={'20px'} color={Theme.colors.info.border}/>
                                </div>
                                <Text margin='15px' fontSize={'16px'}>Channel History</Text>
                            </div>
                        </Item>
                        {timelineVisible && (
                            <Timeline
                                timelineVisible={timelineVisible}
                                toggleTimeline={toggleTimeline}
                                title={channel?.data?.name}
                                sid={channel?.data?.id}
                            />
                        )}
                        {/* <Item style={{ cursor: 'pointer' }} >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <div style={{ height: '20px', width: '20px' }} >
                                    <svg id="Layer_1" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><path d="M7.6 12H22M11.9.8H4.5C3.1.8 2 1.9 2 3.2v17.5c0 1.4 1.1 2.5 2.5 2.5h7.4M18.2 8.2 22 12l-3.8 3.8" fill="none" stroke="#d94c54" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke-miterlimit="10" class="stroke-000000"></path></svg>
                                </div>
                                <Text margin='15px' fontSize={'16px'} color="#D94C54" >
                                    Exit Group</Text>
                            </div>
                        </Item> */}
                    </ItemContainer>
                )}

                {channel?.type === 'duo' && (
                    <ItemContainer margin={'2px 0'} >
                        {/* {channel?.data.isArchive ?
                            <Item style={{ cursor: 'pointer' }} onClick={() => unArchiveGroup()} >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                    <div style={{ height: '25px', width: '25px' }} >
                                        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"><defs><path d="M0 0h48v48H0V0z" id="a"></path></defs><clipPath id="b"><use overflow="visible" href="#a"></use></clipPath><path clip-path="url(#b)" d="m41.09 10.45-2.77-3.36A3.043 3.043 0 0 0 36 6H12c-.93 0-1.76.43-2.31 1.09l-2.77 3.36C6.34 11.15 6 12.03 6 13v25c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V13c0-.97-.34-1.85-.91-2.55zM24 19l11 11h-7v4h-8v-4h-7l11-11zm-13.75-9 1.63-2h24l1.87 2h-27.5z" fill="#d94c54" className="fill-000000"></path></svg>
                                    </div>
                                    <Text margin='15px' fontSize={'16px'} color="#D94C54" >
                                        Unarchive Chat</Text>
                                </div>
                            </Item>
                            :
                            <Item style={{ cursor: 'pointer' }} onClick={() => archiveGroup()} >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                    <div style={{ height: '20px', width: '20px' }} >
                                        <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 2.2 16.1.5C15.9.2 15.5 0 15 0H3c-.5 0-.9.2-1.2.5L.5 2.2C.2 2.6 0 3 0 3.5V16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3.5c0-.5-.2-.9-.5-1.3ZM9 14.5 3.5 9H7V7h4v2h3.5L9 14.5ZM2.1 2l.8-1h12l.9 1H2.1Z" fill="#d94c54" fillRule="evenodd" className="fill-000000"></path></svg>
                                    </div>
                                    <Text margin='15px' fontSize={'16px'} color="#D94C54" >
                                        Archive Chat</Text>
                                </div>
                            </Item>
                        } */}

                        <Item style={{ cursor: 'pointer' }} >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} onClick={() => reportAbuse()}>
                                <div style={{ height: '20px', width: '20px' }} >
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.88 122.88" enableBackground="new 0 0 122.88 122.88" space="preserve" fill='#D94C54' ><g><path fillRule="evenodd" clipRule="evenodd" d="M61.44,0c33.926,0,61.44,27.514,61.44,61.44c0,33.926-27.514,61.439-61.44,61.439 C27.513,122.88,0,95.366,0,61.44C0,27.514,27.513,0,61.44,0L61.44,0z M52.687,90.555H69.44v14.81H52.687V90.555L52.687,90.555z M69.431,82.96H52.691c-1.665-20.343-5.159-29.414-5.159-49.729c0-7.492,6.075-13.57,13.567-13.57s13.57,6.078,13.57,13.57 C74.67,53.535,71.13,62.633,69.431,82.96L69.431,82.96z" /></g></svg>
                                </div>
                                <Text margin='15px' fontSize={'16px'} color="#D94C54" >
                                    Report Abuse</Text>
                            </div>
                        </Item>
                    </ItemContainer>
                )}
            </Container>

            <Modal
                open={isVisible}
                onClose={() => setVisible(false)}
            >
                <img src={channel?.type === 'duo' ? image : channel?.data?.image} height={'auto'} width={'100%'} style={{ objectFit: 'cover', minWidth: '500px' }} />
                {/* <Avatar image={channel?.type === 'duo' ? image : channel?.data?.image} name={channel.type === "duo" ? name : channel?.data.name} size={'100%'} shape='square' /> */}
            </Modal>

        </div>
    )
}

export default ChannelDetails

const Container = styled.div`
background-color: #ECECEC;
display: block;
width: 100%;
`;

const HeaderContainer = styled.div`
z-index: 1;
position: relative;
width:${props => (props.width ? props.width : '100%')};
max-width: 100%;
height: 70px;
min-height: 70px;
top: 0;
display: flex;
align-items: center;
padding: 0 var(--sm-p);
border: none;
background: var(--white);
box-shadow: 0 2px 1px 0 var(--border), 0 1px 0 0 var(--border);
box-sizing: inherit;
`;

const ItemContainer = styled.div`
background: white;
display: block;
padding: 10px;
margin: ${props => (props.margin ? props.margin : 0)};
`;

const Item = styled.div`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display:flex;
`
const ImageChannel = styled.div`
    display: flex;
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    overflow: hidden;
    position: relative;
    border: ${props => (props.border ? props.border : "1px solid #516F90")};
    border-radius: 50%;
    cursor: pointer;

    svg {
        border-radius: 50%;
    }

    img {
        height: 100%;
        width: 100%;
        overflow: scroll;
        margin: auto;
        object-fit: cover;
    }

    ${props => props.bot ? `
        border: 1px solid #F7931E;
        align-items: center;
        justify-content: center;
        svg {
         border-radius: unset;
        }
    `
        : ""}
`;

const IconHover = styled.div`
    height: 150px;
    width: 150px;
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const Content = styled.div`
    box-sizing: border-box;
    display: grid;
    min-height:unset;
    grid-template-rows:25px auto;
`;
