import React, {useContext, useEffect} from "react";
import {NavLink, Route, Switch} from 'react-router-dom';
import {Spacer, SubTitle, Theme, ThirdTitle} from "@dspworkplace/ui";
import {getCompanyId, useAuth} from "../../Auth";
import SettingsContext, {SettingsProvider} from "./context";
import {Content, Menu, Navigator} from "../../components/Menus/Navigator";
import {ROUTES} from '../../Route_temp';
import IsGranted from "../../security/IsGranted";
import Loading, {LoadingWrapper} from "../../components/Loading";

const App = props => {
    return (
        <SettingsProvider>
            <Settings routeProps={props}/>
        </SettingsProvider>
    )
};

export default App;

const Settings = ({routeProps}) => {
    const {api} = useAuth();
    const [state, dispatch] = useContext(SettingsContext);

    const {history, match} = routeProps;
    const {url, isExact} = match;

    const routes = ROUTES.find(r => r.path === '/settings').routes;

    if (isExact)
        history.replace(url + routes[0].routes[0].path);

    useEffect( () => {
        const params = {
            actions: {
                response: {
                    Company: {
                        findOneBy: {
                            criteria: {
                                id: getCompanyId()
                            },
                            get: "company",
                            includes: {
                                0: "id",
                                1: "name",
                                2: "amazonAbbreviation",
                                3: "domain",
                                7: "plivoAuthId",
                                8: "plivoAuthToken",
                                9: "plivoMainSrcNumber",
                                10: "overtimeHours",
                                11: "rescueHours",
                                12: "backupDriverHours",
                                13: "isAdp",
                                14: "clientId",
                                15: "clientSecret",
                                16: "pemFile",
                                17: "keyFile",
                                18: "masterAssociateId",
                                "image": {
                                    0: "path"
                                },
                                21: "showMobileReleaseShift",
                                22: "showMobileSwapShift",
                                23: "botManagers",
                                24: "incidentSummeryTime",
                                25: "defaultAutoUsersChat",
                                26: "isOptOutMissedLunchPunch",
                                27: "swapSwiftAvailability",
                                28: "releaseMinLockingPeriod",
                                29: "recurringWeeks",
                                30: "postAsUnpublish",
                                31: "workHourComplianceWarning",
                                32: "workHourComplianceWarningTimePeriod",
                                33: "workHourComplianceNotifyUsers",
                                34: "expiryNotificationManager",
                                35: "sendNotificationBeforeDays",
                                36: "sendVehicleExpiryNotification",
                                37: "sendDriverLicenceExpiryNotification",
                                38: "driverLicenceExpiryNotiType",
                                39: "allowDriverToSwapWithUnscheduledDriver",
                                40: "allowSameDayBackupDriverSwap",
                            },
                        },
                    },
                    GradingSettings: {
                        findBy: {
                            criteria: {
                                company: getCompanyId()
                            },
                            get: "gradingRules",
                            includes: {
                                0: "id",
                                1: "color",
                                2: "min",
                                3: "max",
                                4: "description"
                            }
                        }
                    },
                    RouteAssistSettings: {
                        findBy: {
                            criteria: {
                                company: getCompanyId()
                            },
                            get: "routeAssistSettings",
                            includes: {
                                0:  "id",
                                20: "enabled",
                                1:  "cortexDspId",
                                2:  "cortexCookiesStatus",
                                3:  "cortexCookiesLastUpdated",
                                4:  "ementorCookiesStatus",
                                5:  "ementorCookiesLastUpdated",
                                18: "ementorUsername",
                                19: "ementorPassword",
                                6:  "netradyneCookiesStatus",
                                7:  "netradyneCookiesLastUpdated",
                                21: "netradyneUsername",
                                22: "netradynePassword",
                                8:  "packageDeliveryProgressEnabled",
                                9:  "packageDeliveryProgressOptions",
                                10: "routeCodeAssignmentEnabled",
                                11: "routeCodeAssignmentOptions",
                                12: "vanAutoAssignmentEnabled",
                                13: "vanAutoAssignmentOptions",
                                14: "assignmentNotificationsEnabled",
                                15: "assignmentNotificationsOptions",
                                16: "safetyDocumentsEnabled",
                                17: "safetyDocumentsOptions",
                                station: ["id", "code"]
                            }
                        }
                    },
                    PaycomSettings: {
                        findOneBy: {
                            criteria: {
                                company: getCompanyId()
                            },
                            get: "paycomSettings",
                            includes: {
                                0: "id",
                                1: "clientCode",
                                2: "username",
                                3: "password",
                                4: "pin1",
                                5: "pin2",
                                6: "pin3",
                                7: "pin4",
                                8: "pin5",
                                9: "isEnabled",
                                10: "clientId",
                                11: "clientSecret"
                            }
                        }
                    },
                    CompanySetting: {
                        findOneBy: {
                            criteria: {
                                company: getCompanyId(),
                            },
                            get: "companySetting",
                            includes: {
                                0: "enableAutomatedScorecardCoaching",
                                1: "rolingWeekPeriod",
                                2: "sendDay",
                                3: "sendTime",
                                4: "defaultCreateCoachingPerDayPerDriver",
                                5: "coachingTemplate",
                                6: "factsOfIncidentStep2",
                                7: "correctiveActionNeededStep2",
                                8: "performanceImprovementPlanStep2",
                                9: "factsOfIncidentStep3",
                                10: "correctiveActionNeededStep3",
                                11: "performanceImprovementPlanStep3",
                                12: "employeeTemplate",
                                13: "ownerSign",
                                14: "requireCompleteIncidentForm1",
                                15: "requireCompleteIncidentForm2",
                                16: "requireCompleteIncidentForm3",
                                17: "requireWatchVideoForm1",
                                18: "requireWatchVideoForm2",
                                19: "requireWatchVideoForm3",
                                20: "allowsDispatcherPermission"
                            },
                        },
                    },
                }
            }
        };

        api.post('/api/lazy/manage/data', params).then(
            response => dispatch({
                type: 'ADD',
                payload: response.data.data
            }),
            error => {}
        );
    }, []);

    return (
        <div style={{display: 'flex', position: 'relative'}}>
            {!state.company.id && <LoadingWrapper>
                <Loading style={{height: 80}}/>
            </LoadingWrapper>}
            <Navigator scrollable>
                <SubTitle>Settings</SubTitle>
                <Spacer top={3} style={{borderBottom: '1px solid ' + Theme.colors.info.bg}}/>
                <Spacer top={4}/>
                {routes.map((r, i) =>
                    <Menu key={i}>
                        <li>
                            <ThirdTitle>{r.name}</ThirdTitle>
                        </li>
                        {r.routes.map((p, k) => {
                            if (p.isCompany && p.isCompany.indexOf(parseInt(getCompanyId()))) {
                                return null;
                            }
                            else {
                                return (    <li key={k}>
                                        <NavLink to={`${url}${p.path}`} dangerouslySetInnerHTML={{__html: p.name}}/>
                                </li>)
                            }
                        })}
                    </Menu>
                )}
                <Spacer top={16} />
            </Navigator>
            <Content scrollable style={{position: 'relative'}}>
                <Switch>
                    {routes.map((r, i) => r.routes.map(({path, feature, component}, k) =>
                        <Route
                            path={`${url}${path}`}
                            key={i+k}
                            render={props =>
                                <IsGranted
                                    feature={feature}
                                    renderWarning={true}
                                    renderLoading={true}
                                >{React.createElement(component, props)}</IsGranted>
                            }
                        />
                    ))}
                </Switch>
            </Content>
        </div>
    )
}